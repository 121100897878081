import DS from 'ember-data';

export type LegacyLoginUpgradeMode = 'Disabled' | 'Optional' | 'Required';

export default class OloAuthConfigModel {
  @DS.attr('string')
  apiUrl!: string;

  @DS.attr('boolean')
  isEnabled!: boolean;

  @DS.attr('boolean')
  isLoyaltyLinkingEnabled!: boolean;

  @DS.attr('string')
  legacyLoginUpgradeMode!: LegacyLoginUpgradeMode;
}
