import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { HeadingLevel } from 'mobile-web/components/heading';
import { Segment } from 'mobile-web/components/segmented-control';
import { Customer } from 'mobile-web/lib/customer';
import BootstrapService from 'mobile-web/services/bootstrap';
import FeaturesService from 'mobile-web/services/features';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

const LABEL_SIGN_IN = 'Sign In';
const LABEL_CREATE_ACCOUNT = 'Create Account';

export enum Mode {
  SignIn,
  CreateAccount,
}

const MODES: Segment<Mode>[] = [
  {
    label: LABEL_SIGN_IN,
    value: Mode.SignIn,
  },
  {
    label: LABEL_CREATE_ACCOUNT,
    value: Mode.CreateAccount,
  },
];

interface Args {
  // Required arguments
  mode: Mode;
  onCreateAccount: () => void;
  onCreateAccountError: (error: unknown) => void;
  onModeChange: (mode: Mode) => void;
  onSignIn: (email: string, password: string) => void;

  // Optional arguments
  guestUser?: Customer;
  headingLevel?: HeadingLevel;
  isUpgrade?: boolean;
  lastOrderId?: EmberDataId;
  previousOrderIds?: EmberDataId[];
  optIn?: boolean;
}

interface Signature {
  Args: Args;
}

export default class SignInCreateAccountForm extends Component<Signature> {
  // Service injections
  @service features!: FeaturesService;
  @service session!: SessionService;
  @service bootstrap!: BootstrapService;

  // Untracked properties
  modes = MODES;
  style = style;

  // Tracked properties

  // Getters and setters
  get headingLevel(): HeadingLevel {
    return this.args.headingLevel ?? 1;
  }

  get isSignInMode(): boolean {
    return this.args.mode === Mode.SignIn;
  }

  get hideCreateAccount(): boolean {
    return (
      this.features.allFlags['olo-auth-hide-legacy-account-creation'] &&
      this.session.supportsLegacyLoginUpgrade &&
      !!this.bootstrap.data?.hasOloAuthProvider
    );
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  switchModes(mode: Segment<Mode>) {
    this.args.onModeChange(mode.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SignInCreateAccountForm: typeof SignInCreateAccountForm;
  }
}
