import Controller from '@ember/controller';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import Router from '@embroider/router';

import config from 'mobile-web/config/environment';
import ApplicationController from 'mobile-web/controllers/application';
import { NoopTransition } from 'mobile-web/lib/noop-transition';
import { homeRoute, PreviousRoute } from 'mobile-web/lib/routing';
import { join } from 'mobile-web/lib/utilities/path';
import FeaturesService from 'mobile-web/services/features';
import SecurityService from 'mobile-web/services/security';
import WindowService from 'mobile-web/services/window';

class CustomRouter extends Router {
  @service router!: RouterService;
  @service window!: WindowService;
  @service security!: SecurityService;
  @service features!: FeaturesService;

  location = config.locationType;

  rootURL = config.rootURL;

  get _currentRouteName() {
    const rootURL = this.router.rootURL;
    const currentURL = this.router.currentURL;
    if (!currentURL) {
      return undefined;
    }

    const url = join(rootURL, currentURL);

    const route = this.router.recognize(url);

    return route.name;
  }

  _doTransition(
    targetRouteName: string | undefined,
    models: UnknownObject[],
    queryParams: UnknownObject
  ) {
    const currentSearchParams = new URLSearchParams(this.window.location().search);
    if (currentSearchParams.has('src')) {
      queryParams = { ...queryParams, src: currentSearchParams.get('src') };
    }

    if (this._shouldHardReload(this._currentRouteName, targetRouteName, queryParams)) {
      this._reload(targetRouteName, models, queryParams);

      return new NoopTransition();
    }
    // @ts-expect-error -_doTransition is not a part of Routers' public API
    return super._doTransition(targetRouteName, models, queryParams);
  }

  _reload(targetRouteName: string, models: UnknownObject[], queryParams: UnknownObject) {
    const newUrl = this.router.urlFor(targetRouteName, ...models, { queryParams });
    this.window.location().assign(newUrl);
  }

  _shouldHardReload(
    currentRoute?: string,
    newRoute?: string,
    queryParams?: UnknownObject
  ): newRoute is string {
    if (!newRoute) {
      // intial page load
      return false;
    }

    return (
      this.canReload &&
      (this.security.routeIsSecure(currentRoute) !== this.security.routeIsSecure(newRoute) ||
        !!queryParams?.forceReload)
    );
  }

  get canReload(): boolean {
    return !config.preventReload;
  }
}

Route.reopen({
  previousRoute: homeRoute,

  setupController(
    this: Route & { previousRoute: PreviousRoute },
    controller: Controller,
    model: UnknownObject
  ) {
    this._super(controller, model);

    // eslint-disable-next-line ember/no-controller-access-in-routes
    (this.controllerFor('application') as ApplicationController).set(
      'previousRoute',
      this.previousRoute
    );
  },
});

/**
 * The routes listed here are documented in `/docs/pages`.
 * Please be sure to update the documentation when making changes.
 */
Router.map(function () {
  /* Menu Routes */
  this.route('menu', function () {
    this.route('index', { path: '/' });
    this.route('vendor', { path: '/:vendor_slug' }, function () {
      this.route('products', { path: '/products/:product_id' });
    });

    // Intentionally not nested under `vendor` route above
    // because we don't want them to be rendered as child routes
    this.route('category', { path: '/:vendor_slug/categories/:category_id' });
    this.route('vendor.single-use', { path: '/:vendor_slug/single-use' });
    this.route('vendor.info', { path: '/:vendor_slug/info' });
    this.route('vendor.rewards', { path: '/:vendor_slug/rewards' });
    this.route('vendor.reward', { path: '/:vendor_slug/rewards/:reward_reference' });
  });

  /* Basket Routes */
  this.route('basket', function () {
    this.route('basket-products.edit', { path: '/products/:basket_product_id/edit' });
  });

  /* Participating Locations */
  this.route('vendor-search-results', { path: 'search' });
  this.route('rewards-search', { path: 'rewards/search' });

  this.route('feedback');
  this.route('contact-us');
  this.route('user-agreement');
  this.route('open-source-licenses');
  this.route('privacy-policy');
  this.route('opt-out-guide', { path: '/online-tracking-opt-out-guide' });
  this.route('locations');
  this.route('region-results', { path: 'locations/:region_code' });

  this.route('thank-you', { path: 'thank-you/:order_id' });
  this.route('order-summary', { path: 'orders/:order_id' });

  this.route('additional-info', { path: 'additional-info/:linkTitle' }); // eslint-disable-line ember/routes-segments-snake-case

  /* Group Order Routes */

  /* Pay at Table Routes */
  this.route('pay', { path: 'pay/:vendor_slug' });
  this.route('pay-select', { path: 'pay/:vendor_slug/select' });

  /* Error Routes */
  this.route('outage');
  // If we don't resolve the route for some reason, render a 404.
  this.route('fourOhFour', { path: '*path' }); // eslint-disable-line ember/no-capital-letters-in-routes

  this.route('frequently-asked-questions');
  this.route('forgot-password');
  this.route('favorites');
  this.route('recent-orders');
  this.route('checkin', { path: 'order/checkin/:id' });
  this.route('arrival', { path: 'order/arrival/:id' });

  this.route('dispatch-summary', { path: 'orders/:order_id/dispatch-statuses' });
  this.route('dispatch-status', { path: 'orders/:order_id/dispatch-statuses/:status_id' });

  this.route('challenge');
  this.route('secure-challenge');

  // secure routes
  this.route('signup');
  this.route('login');
  this.route('reset-password', { path: 'resetpassword' });

  this.route('my-account');
  this.route('my-account.change-password', { path: 'my-account/change-password' });
  this.route('my-account.contact-info', { path: 'my-account/contact-info' });

  this.route('checkout');
  this.route('checkout.auth', { path: 'checkout/auth' });

  this.route('group-order.start', { path: 'group-order/start' });
  this.route('group-order.join', { path: 'group-order/join' });
  this.route('group-order.cancel', { path: 'group-order/cancel' });
  this.route('group-order.update-participant-status', {
    path: 'group-order/update-participant-status',
  });
  this.route('group-order.participant-confirmation', {
    path: 'group-order/participant-confirmation',
  });

  this.route('open-check.review', { path: 'open-check/review' });
  this.route('open-check.confirmation', { path: 'open-check/confirmation' });

  this.route('user-upgrade', { path: 'user/upgrade' });

  // compatability routes (for parity with Ghost)
  this.route('compat.reset-password', { path: 'user/resetpassword' });
});

/**
 * Maps a route name to a friendly page name. Primarily used for analytics.
 * @param route the route name to map
 */
export function routeToPageName(route: string): string | undefined {
  switch (route) {
    case 'index':
      return 'Home Page';
    case 'application_loading':
      return 'Loading Page';
    case 'menu.vendor':
    case 'menu.vendor.index':
      return 'View Vendor Menu Page';
    case 'menu.vendor.products':
      return 'View Product Customization';
    case 'menu.category':
      return 'View All Category Page';
    case 'menu.vendor.rewards':
      return 'View Rewards';
    case 'basket.basket-products.edit':
      return 'Edit Product';
    case 'vendor-search-results':
      return 'Search Locations Page';
    case 'rewards-search':
      return 'Search Rewards Page';
    case 'feedback':
      return 'Feedback Page';
    case 'contact-us':
      return 'Contact Us Page';
    case 'open-source-licenses':
      return 'Open Source Licenses Page';
    case 'user-agreement':
      return 'User Agreement Page';
    case 'privacy-policy':
      return 'Privacy Policy Page';
    case 'opt-out-guide':
      return 'Online Tracking Opt Out Guide';
    case 'locations':
      return 'View Locations Page';
    case 'region-results':
      return 'View Locations in Region Page';
    case 'thank-you':
      return 'Thank You Page';
    case 'order-summary':
      return 'Order Details Page';
    case 'additional-info':
      return 'Custom Link Page';
    case 'outage':
      return 'Outage';
    case 'frequently-asked-questions':
      return 'FAQs Page';
    case 'forgot-password':
      return 'Forgot Password Page';
    case 'favorites':
      return 'Favorite Orders Page';
    case 'recent-orders':
      return 'Recent Orders Page';
    case 'checkin':
      return 'Manual Fire Check-In Page';
    case 'arrival':
      return 'Customer Arrivals Success Page';
    case 'dispatch-summary':
      return 'Dispatch Multiple Deliveries Summary Page';
    case 'dispatch-status':
      return 'Dispatch Tracker Page';
    case 'login':
      return 'Log In Page';
    case 'reset-password':
      return 'Reset Password Page';
    case 'my-account':
      return 'My Accounts Page';
    case 'my-account.change-password':
      return 'Change Password Page';
    case 'checkout':
      return 'Checkout Page';
    case 'checkout.auth':
      return 'Checkout Log In Page';
    case 'group-order.start':
      return 'Start Group Order Page';
    case 'group-order.join':
      return 'Join Group Order Page';
    case 'group-order.participant-confirmation':
      return 'Group Order Participant Confirmation Page';
    case 'group-order.cancel':
      return 'Group Order Cancelled Page';
    case 'open-check.review':
      return 'Review Open Check';
    case 'user-upgrade':
      return 'User Upgrade Page';
    default:
      return undefined;
  }
}

export default CustomRouter;
